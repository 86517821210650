<template>
    <table class="table table-borderless table-sm mb-0" border="0">
        <tbody>
        <tr>
            <td width="20px" class="px-0">
                <div v-if="stepDisabled === true || stepDisabled === 'true'">
                    <img v-if="stepNumber === '1'" alt="number1" src="../assets/number/number_circle_one_icon.svg" style="width: 20px">
                    <img v-if="stepNumber === '2'" alt="number1" src="../assets/number/number_circle_two_icon.svg" style="width: 20px">
                    <img v-if="stepNumber === '3'" alt="number1" src="../assets/number/number_circle_three_icon.svg" style="width: 20px">
                    <img v-if="stepNumber === '4'" alt="number1" src="../assets/number/number_circle_four_icon.svg" style="width: 20px">
                </div>
                <div v-else>
                    <img v-if="stepNumber === '1'" alt="number1" src="../assets/number/number_circle_one_fill_icon.svg" style="width: 20px">
                    <img v-if="stepNumber === '2'" alt="number2" src="../assets/number/number_circle_two_fill_icon.svg" style="width: 20px">
                    <img v-if="stepNumber === '3'" alt="number3" src="../assets/number/number_circle_three_fill_icon.svg" style="width: 20px">
                    <img v-if="stepNumber === '4'" alt="number4" src="../assets/number/number_circle_four_fill_icon.svg" style="width: 20px">
                </div>
            </td>
            <td class="align-middle" style="color: #212529">
                <span v-if="stepExpandContent === true" class="me-1" :class="stepDescClass" style="cursor: pointer">{{ stepDesc }}</span> 
                <span v-else class="me-1" :class="stepDescClass" style="cursor: pointer">
                    <span v-if="stepDesc2 && stepDesc2.length > 22">
                        <!-- Use tooltips insead of popper as popper conflict with signatory 
                         <Popper class="popperDark" arrow hover :content="stepDesc2"> -->
                            <span data-bs-toggle="tooltip" data-bs-placement="top" :title="stepDesc2">{{ stepDesc2.substring(0, 22) + '...' }}</span>
                        <!-- </Popper> -->
                    </span>
                    <span v-else>
                        {{stepDesc2}}
                    </span>
                </span> 

                <span v-if="stepExpandContent === true && stepTotalSignee !== undefined" class="badge bg-grey">
                    <fa icon="user" /> {{ stepTotalSignee }}
                </span>
                <span v-if="stepTotalDoc !== undefined" class="badge bg-grey">
                    <fa icon="file" /> {{ stepTotalDoc }}
                </span>
            </td>
            <td class="text-end">
                <fa v-if="stepExpandContent === false" icon="chevron-down" class="me-1 isLinkIcon" />
                <fa v-if="stepExpandContent === true" icon="chevron-up" class="me-1 isLinkIcon" />
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import {ref, onMounted} from 'vue'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.js'
// import Popper from 'vue3-popper'

export default {
    name: 'Step',
    components: { /* Popper */ Tooltip },
    props: [ 'number', 'desc', 'desc2', 'descClass', 'totalSignee', 'totalDoc', 'disabled', 'expandContent' ],
    setup (props) {

        const stepNumber = ref(props.number)
        const stepDesc = ref(props.desc)
        const stepDesc2 = ref(props.desc2)
        const stepDescClass = ref(props.descClass)
        const stepTotalSignee = ref(props.totalSignee)
        const stepTotalDoc = ref(props.totalDoc)
        const stepDisabled = ref(props.disabled)
        const stepExpandContent = ref(props.expandContent)

        onMounted(() => {

            // Initiate tooltip
            Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))
            Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))
        })

        return { stepNumber, stepDesc, stepDesc2, stepDescClass, stepTotalSignee, stepDisabled, stepExpandContent, stepTotalDoc }
    }
}
</script>

<style>
.step-number {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 25px;
    height: 25px;
    padding: 10px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 9999px;
    color: #FFFFFF;
    line-height: 1;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0;
    text-transform: uppercase;
    font-size: 11px;
    background-color: var(--bs-primary);
}

.step-number-disabled {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 25px;
    height: 25px;
    padding: 10px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 9999px;
    color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
    line-height: 1;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0;
    text-transform: uppercase;
    font-size: 11px;
    background-color: --bgcolor-body-color
}
</style>